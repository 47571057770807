import { ROLE_STAFF_SALES, ROLE_ADMIN } from '~/const/user/roles'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore()

    const authorizedRoleIds = [ROLE_STAFF_SALES, ROLE_ADMIN]

    if (!auth.authenticated) {
        return navigateTo('/login')
    }

    if (!authorizedRoleIds.includes(auth.user.role)) {
        return navigateTo('/login')
    }
})
